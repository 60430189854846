<template lang="pug">
.container.bg-white.rounded-bottom
  .row
    .col.bg-blue.rounded-top.py-2
      .d-flex.justify-content-center
        span.text-white.h3
          | {{ title }}
  .row.border-dark.py-4
    .col-md-6.d-flex.justify-content-center
      font-awesome-icon(icon="calendar-minus" size="lg").mr-2
      | {{ classTime }}
    .col-md-3.d-flex.justify-content-center
      font-awesome-icon(icon="user" size="lg").mr-2
      | {{ consultantName }}
  .row
    .col
      hr
  .row.d-flex.justify-content-center
    .col-md-10
      .row.my-3
        .col-md.d-flex.justify-content-between.align-items-center
          strong
            li.h5.m-0 {{ $t('overallLevel') }}
          .col-2.btn.text-center(
            v-if="!isUnfilled(overallLevel)"
            :class="levelClass(overallLevel)"
          ) {{ overallLevel }}
          .col-2.btn.btn-secondary.text-center(v-else) ---
      .row.my-3
        .col-md.d-flex.justify-content-between.align-items-center
          strong
            li.h5.m-0 {{ $t('consultantRating') }}
          .col-2.btn.text-center(
            v-if="!isUnfilled(consultantRating)"
            :class="levelClass(consultantRating)"
          ) {{ consultantRating }}
          .col-2.btn.btn-secondary.text-center(v-else) ---
      // (3)分配到適當的表達時間
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('expressTimeRating') }}
              .col-2.btn.text-center(
                v-if="!isUnfilled(expressTimeRating)"
                :class="levelClass(expressTimeRating)"
              ) {{ expressTimeRating }}
              .col-2.btn.btn-secondary.text-center(v-else) ---
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ suggestionExpressTimeRating ? suggestionExpressTimeRating : '---' }}
      // (4)顧問有給予適當回饋
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('adviceRating') }}
              .col-2.btn.text-center(
                v-if="!isUnfilled(adviceRating)"
                :class="levelClass(adviceRating)"
              ) {{ adviceRating }}
              .col-2.btn.btn-secondary.text-center(v-else) ---
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ suggestionAdviceRating ? suggestionAdviceRating : '---' }}
      // (5)顧問的教學內容時間分配與掌握
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('overallTimeRating') }}
              .col-2.btn.text-center(
                v-if="!isUnfilled(overallTimeRating)"
                :class="levelClass(overallTimeRating)"
              ) {{ overallTimeRating }}
              .col-2.btn.btn-secondary.text-center(v-else) ---
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ suggestionOverallTimeRating ? suggestionOverallTimeRating : '---' }}
      // (6)教材滿意度
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('materialRating') }}
              .col-2.btn.text-center(
                v-if="!isUnfilled(materialRating)"
                :class="levelClass(materialRating)"
              ) {{ materialRating }}
              .col-2.btn.btn-secondary.text-center(v-else) ---
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ suggestionMaterialRating ? suggestionMaterialRating : '---' }}
      // (7)通訊狀況滿意度
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('communicationRating') }}
              .col-2.btn.text-center(
                v-if="!isUnfilled(communicationRating)"
                :class="levelClass(communicationRating)"
              ) {{ communicationRating }}
              .col-2.btn.btn-secondary.text-center(v-else) ---
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ suggestionCommunicationRating ? suggestionCommunicationRating : '---' }}
      // TODO: (8)與客服的悄悄話
      .panel-evaluation-content(v-if="isVip()")
        .d-flex.evaluation-content-title.col-md-6.col-8
          .icon-evaluation-content
          .txt-evaluation-content-title {{ $t('feedback') }}
        .txt-evaluation-content.col-md-11 {{ feedback }}
      // (9)給顧問的建議
      .row.my-3
        .col
          .row
            .col-md.d-flex.justify-content-between.align-items-center
              strong
                li.h5.m-0 {{ $t('hbSuggestion') }}
          .row
            .col-md
              p.m-0
                b.mr-2 {{ $t('suggestion') }}:
                | {{ hbSuggestion ? hbSuggestion : '---' }}

</template>

<script>
import moment from 'moment';

import i18n from './lang';

import api from '@lioshutan/api-package';

export default {

  components: {
  },

  i18n,

  data() {
    return {
      token: '',
      title: '',
      classTime: '',
      consultantName: '',

      overallLevel: 0,
      consultantRating: 0,
      expressTimeRating: 0,
      adviceRating: 0,
      overallTimeRating: 0,
      materialRating: 0,
      communicationRating: 0,

      suggestionExpressTimeRating: '',
      suggestionAdviceRating: '',
      suggestionOverallTimeRating: '',
      suggestionMaterialRating: '',
      suggestionCommunicationRating: '',
      hbSuggestion: '',

      feedback: '',
    };
  },
  async created() {
    const loading = this.$loading.show();
    this.token = this.$store.state.auth.token;
    const id = this.$route.params['evaluationId'];
    const {
      data: {
        vipEvaluation,
      },
    } = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .analysis(this.token).consultantGetStudentEvaluation({
        evaluationId: id,
      });
    this.title = vipEvaluation.classroom.title;
    this.classTime = vipEvaluation.classroom.classTime;
    this.consultantName = vipEvaluation.consultant.englishName;

    this.overallLevel = vipEvaluation.overallLevel;
    this.consultantRating = vipEvaluation.consultantRating;
    this.expressTimeRating = vipEvaluation.expressTimeRating;
    this.adviceRating = vipEvaluation.adviceRating;
    this.overallTimeRating = vipEvaluation.overallTimeRating;
    this.materialRating = vipEvaluation.materialRating;
    this.communicationRating = vipEvaluation.communicationRating;

    this.suggestionExpressTimeRating = vipEvaluation.suggestionExpressTimeRating;
    this.suggestionAdviceRating = vipEvaluation.suggestionAdviceRating;
    this.suggestionOverallTimeRating = vipEvaluation.suggestionOverallTimeRating;
    this.suggestionMaterialRating = vipEvaluation.suggestionMaterialRating;
    this.suggestionCommunicationRating = vipEvaluation.suggestionCommunicationRating;
    this.hbSuggestion = vipEvaluation.hbSuggestion;

    this.feedback = vipEvaluation.feedback;
    loading.hide();
  },

  methods: {

    levelClass(rating) {
      if (rating >= 80) {
        return 'btn-success';
      } else if (rating >= 60) {
        return 'btn-warning';
      } else if (rating < 60) {
        return 'btn-danger';
      } else {
        return 'btn-secondary';
      }
    },

    isVip() {
      // [8, 21, 37, 44, 45, 47]
      // TODO: 該部分要判斷是否擁有權限
      return false;
    },

    isUnfilled(rating) {
      return !rating && moment(this.classTime).isBefore('2020-05-26');
    },
  },
};
</script>
