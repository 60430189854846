export default {
  messages: {

    en: {
      back: 'Back',
      date: 'Date',
      consultant: 'Consultant',
      overallLevel: 'Overall Level',
      consultantRating: 'Consultant Rating',
      expressTimeRating: 'I have been given enough time to express my thoughts',
      adviceRating: 'Adequate feedback from the consultant ( correcting mistakes/giving encouragement)',
      overallTimeRating: 'Consultant\'s time management on the material',
      materialRating: 'Class material satisfaction',
      communicationRating: 'Connection stability',
      feedback: 'Feedback',
      hbSuggestion: 'HB Suggestion',
      notFilled: 'Not Filled',
      noOpportunity: 'No Opportunity',
      littleOpportunity: 'Little Opportunity',
      haveOpportunity: 'Have Opportunity',
      suggestion: 'Suggestion',
    },

    tw: {
      back: '返回',
      date: '日期',
      consultant: '顧問',
      overallLevel: '整體評價',
      consultantRating: '顧問教學滿意程度',
      expressTimeRating: '表達時間滿意程度',
      adviceRating: '顧問有適當給予我回饋',
      overallTimeRating: '顧問教學時間分配與掌握',
      materialRating: '教材滿意程度',
      communicationRating: '通訊狀況滿意程度',
      feedback: '與客服的悄悄話',
      hbSuggestion: '想跟顧問說點什麼呢？',
      notFilled: '未填寫',
      noOpportunity: '完全沒機會表達',
      littleOpportunity: '很少表達的機會',
      haveOpportunity: '能表達的時間剛剛好',
      suggestion: '建議',
    },

  },
};
